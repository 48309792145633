import React from "react";
import { graphql } from "gatsby";
import { GatsbySeo, BreadcrumbJsonLd } from "gatsby-plugin-next-seo";
import Layout from "~/components/layout";
//import PageHeading from "~/components/styled/page-heading";
import Image from "~/components/image";
import TourList from "~/components/tour-list";
//import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import stars from "~/images/stars-4.5.svg";
import tustbadge from "~/images/trust-badge.png";
import { CheckIcon, ArrowRightIcon } from "@heroicons/react/outline";

const SpecialityPage = ({ data }) => {
	console.log(data);
	const tours = data.allStrapiTour.edges;
	const flatTours = tours.map(({ node }) => node);

	const contactTripUTM = new URLSearchParams({
		utm_source: "landing",
		utm_medium: "cta-button",
		utm_campaign: "contact-us",
	}).toString();

	const contactTripWithUTM = `/contact-us/?${contactTripUTM}`;
	// const seo = {
	//   title: data.strapiSpeciality.name,
	// }

	const asia = [
		{
			name: "Albania",
			to: "/destinations/albania-bike-tours/",
		},
		{
			name: "Bhutan",
			to: "/destinations/bhutan-cycling-tours/",
		},
		{
			name: "Cambodia",
			to: "/destinations/cambodia-bike-tours/",
		},
		{
			name: "Chile",
			to: "/destinations/chile-bike-tours/",
		},
		{
			name: "Croatia",
			to: "/destinations/croatia-bike-tours/",
		},
		{
			name: "Czech Republic",
			to: "/destinations/czech-republic-bike-tours/",
		},
		{
			name: "France",
			to: "/destinations/france-bike-tours/",
		},
		{
			name: "Greece",
			to: "/destinations/greece-bike-tours/",
		},
		{
			name: "India",
			to: "/destinations/india-bicycle-tours/",
		},
		{
			name: "Italy",
			to: "/destinations/italy-bike-tours/",
		},
		{
			name: "Jordan",
			to: "/destinations/jordan-bike-tours/",
		},
		{
			name: "Japan",
			to: "/destinations/japan-bike-tours/",
		},
		{
			name: "Laos",
			to: "/destinations/laos-bike-tours/",
		},
		{
			name: "Mongolia",
			to: "/destinations/mongolia-bike-tours/",
		},
		{
			name: "Montenegro",
			to: "/destinations/montenegro-bike-tours/",
		},
		{
			name: "Morocco",
			to: "/destinations/morocco-bike-tours/",
		},
		{
			name: "Nepal",
			to: "/destinations/nepal-bike-tours/",
		},
		{
			name: "Norway",
			to: "/destinations/norway-bike-tours/",
		},
		{
			name: "Philippines",
			to: "/destinations/philippines-bike-tours/",
		},
		{
			name: "Poland",
			to: "/destinations/poland-bike-tours/",
		},
		{
			name: "Portugal",
			to: "/destinations/portugal-bike-tours/",
		},
		{
			name: "Romania",
			to: "/destinations/romania-bike-tours/",
		},
		{
			name: "Slovenia",
			to: "/destinations/slovenia-bike-tours/",
		},
		{
			name: "Sri Lanka",
			to: "/destinations/sri-lanka-cycling-tours/",
		},
		{
			name: "Spain",
			to: "/destinations/spain-bike-tours/",
		},
		{
			name: "Taiwan",
			to: "/destinations/taiwan-bike-tours/",
		},
		{
			name: "Thailand",
			to: "/destinations/thailand-bike-tours/",
		},
		{
			name: "Vietnam",
			to: "/destinations/vietnam-bike-tours/",
		},
	];

	return (
		<Layout>
			{/* <SEO seo={seo} /> */}
			<GatsbySeo
				title={`${data.strapiSpeciality.name}`}
				description={`Join our ${data.strapiSpeciality.name} and experience the adventure of a lifetime.`}
				// languageAlternates={[
				// 	{
				// 		hrefLang: "en-US",
				// 		href: `https://www.artofbicycletrips.com/activities/${data.strapiSpeciality.slug}`,
				// 	},
				// 	{
				// 		hrefLang: "en",
				// 		href: `https://www.artofbicycletrips.com/activities/${data.strapiSpeciality.slug}`,
				// 	},
				// 	{
				// 		hrefLang: "en-IN",
				// 		href: `https://www.artofbicycletrips.com/en-IN/activities/${data.strapiSpeciality.slug}`,
				// 	},
				// 	{
				// 		hrefLang: "x-default",
				// 		href: `https://www.artofbicycletrips.com/activities/${data.strapiSpeciality.slug}`,
				// 	},
				// ]}
			/>
			<BreadcrumbJsonLd
				itemListElements={[
					{
						position: 1,
						name: "Specialities",
						item: "https://artofbicycletrips.com/specialities",
					},
					{
						position: 2,
						name: `${data.strapiSpeciality.name}`,
					},
				]}
			/>
			{/* like Hero */}

			<section className="relative bg-white border-b-0 border-gray-900 overflow-hidden">
				<div className="flex flex-col lg:flex-row items-center">
					{/* Left Half */}
					{/* <div className="lg:w-1/2 py-8 px-8 lg:px-0 lg:pr-20 text-center lg:text-left">
						<div className="flex flex-col items-center lg:items-start">
							<h1 className="text-2xl text-black font-normal sm:text-4xl md:text-4xl lg:text-4xl xl:text-4xl">
								<span className="text-primary "> {data.strapiSpeciality.name}</span>
							</h1>
							<p className="mt-3 text-lg sm:text-xl mb-10 text-gray-600 ">
								We're all about small and local details.
							</p>
						</div>
					</div> */}

					{/* Left Half */}
					<div className="lg:w-1/2 py-8 px-8 lg:px-0 lg:pr-20 text-center lg:text-left">
						<div className="flex flex-col items-center lg:items-start">
							<h1 className="text-2xl capitalize text-black font-normal sm:text-4xl md:text-4xl lg:text-4xl xl:text-4xl">
								{/* <span className="font-light   ">Premier</span>{" "} */}
								<span className="text-black font-bold">
									{" "}
									{data.strapiSpeciality.name}
								</span>
							</h1>
							<p className="mt-3 text-base sm:text-lg mb-10 text-gray-600 ">
								Boutique and personalized{" "}
								<span className="lowercase">{data.strapiSpeciality.name}</span> for
								every journey.
							</p>
							{/* <p className="mt-3 text-base sm:text-lg mb-10 text-gray-600 ">
								We're all about small and local details.
							</p> */}

							<div className="mb-10 ">
								<CheckIcon className="h-6 w-6 bg-primary2 text-white rounded-full p-1 inline-flex mr-2" />
								<span className="text-base text-primary2 font-medium ">
									E-bikes Available
								</span>
							</div>

							<div className="flex flex-col w-full md:w-auto md:flex-row  md:space-x-4">
								<a
									href="#explore"
									className="bg-primary mb-4 md:mb-0 capitalize tracking-wide font-medium text-white px-4 py-3 md:py-4 md:px-8 rounded-full"
								>
									Explore trips
								</a>
								<Link
									to="/design-trip/"
									className="bg-white capitalize border font-medium tracking-wide  border-primary text-primary px-4 py-3 md:py-4 md:px-6 rounded-full"
								>
									Design private trip
								</Link>
							</div>
						</div>
					</div>

					{/* Right Half */}
					<div className="lg:w-1/2 mt-2 lg:mt-0">
						<Image
							className="rounded-lg shadow-md transform border border-gray-200"
							image={data.strapiSpeciality.image}
							alt={data.strapiSpeciality.image.name.split("-").join(" ").split(".")[0]}
							loading="lazy"
						/>
					</div>
				</div>
			</section>

			<section>
				<div className="mt-20">
					{/* trustpilot */}
					<div className="text-center my-10">
						<a
							href="https://www.trustpilot.com/review/artofbicycletrips.com"
							target="_blank"
							rel="noopener noreferrer"
							className="border border-gray-300 p-4 inline-block mx-auto"
						>
							<div className="text-center sm:flex sm:justify-center">
								<div className="flex flex-row justify-center items-center">
									<p className="text-base sm:text-lg font-semibold">Excellent</p>
									<img
										src={stars}
										alt="Trustpilot Logo"
										className="w-24 h-auto sm:w-28 sm:h-auto ml-4 inline-block"
									/>
								</div>
								<div className="flex flex-row mt-1 sm:mt-0 justify-center items-center">
									<p className="text-sm sm:text-sm font-normal sm:ml-3">4.6 out of 5</p>
									<img
										src={tustbadge}
										alt="Trustpilot Logo"
										className="w-auto h-6 sm:w-auto sm:h-6 ml-2 inline-block"
									/>
								</div>
							</div>
						</a>
					</div>
					<h2 className="text-center my-10">
						The Best {data.strapiSpeciality.name}
					</h2>
					{/* <div className="text-center">
					<PageHeading>{data.strapiActivity.name} Tours</PageHeading>
				</div> */}

					<p className="  mb-16 font-light mx-auto max-w-2xl">
						{data.strapiSpeciality.desc}
					</p>
				</div>
			</section>

			{/* <section className="relative lg:pb-28 xl:pb-24 bg-white border-b-0 border-gray-900 overflow-hidden">
				<div className="max-w-7xl mx-auto">
					<div className="relative pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
						<div className="mt-10 mx-auto lg:mx-0 max-w-xl sm:mt-12 md:mt-16 lg:mt-20 xl:mt-28">
							<div className="sm:text-center  lg:text-left">
								<h1 className=" text-gray-900 lg:text-3xl  xl:text-4xl">
									<span className="block text-primary  xl:inline font-normal">
										{data.strapiSpeciality.name}
									</span>{" "}
								</h1>

								<p className="mt-3 mb-10 leading-relaxed sm:mt-5 sm:max-w-md sm:mx-auto md:mt-5 lg:mx-0">
									We're all about small and local details.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="lg:absolute lg:pl-8 lg:inset-y-0 lg:right-0 lg:w-1/2 ">
					<div className="relative">
						<Image
							className="rounded-md shadow-md transform border border-gray-200"
							image={data.strapiSpeciality.image}
							alt={data.strapiSpeciality.image.name.split("-").join(" ").split(".")[0]}
							loading="lazy"
						/>
					</div>
				</div>
			</section> */}
			{/* {data.strapiSpeciality.image && (
				<div className="">
					<Image
						className="rounded shadow-md transform"
						image={data.strapiSpeciality.image}
						alt={data.strapiSpeciality.image.name.split("-").join(" ").split(".")[0]}
						loading="lazy"
					/>
				</div>
			)} */}
			{/* Region List Start */}
			<div className="flex flex-row space-x-2 overflow-x-auto whitespace-nowrap capitalize pt-8 pb-12">
				{asia.map((reg) => {
					return (
						<Link key={reg.name} to={`${reg.to}`}>
							<div className="">
								<span className="border border-primary p-4 md:p-6 rounded-lg">
									{reg.name}
								</span>
							</div>
						</Link>
					);
				})}
			</div>
			{/* Region List End */}
			<section id="explore" className="mb-20">
				<div className="bg-landing py-20 px-4 md:px-8 lg:px-10 xl:px-12 rounded-2xl">
					<h2 className="mb-10">Explore Our {data.strapiSpeciality.name}</h2>

					<TourList tours={flatTours} />
				</div>
			</section>

			{/* CTA */}
			<section className="mt-28">
				<div className="flex flex-col bg-landing rounded-lg px-6 py-10 mb-20 md:px-12 md:py-20 lg:flex-row lg:justify-between items-center">
					<div className="max-w-xl text-center lg:text-left">
						<h3 className="tracking-wide font-semibold text-black uppercase text-2xl md:text-3xl">
							Let's Start Planning!
						</h3>
						<p className=" mt-2 text-lg md:text-xl">
							Contact us today to book your bike tour or inquire about custom options.
						</p>
					</div>
					<div className="mt-10 lg:mt-10 xl:mt-0">
						<button className="">
							<Link
								to={contactTripWithUTM}
								className="text-white font-medium tracking-wide bg-articlecta px-6 py-3 md:py-4 md:px-6 rounded-full "
							>
								Contact us <ArrowRightIcon className="h-4 w-4 inline-flex" />
							</Link>
						</button>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export const query = graphql`
	query SpecialityQuery($slug: String!) {
		allStrapiTour(
			filter: { specialities: { elemMatch: { slug: { eq: $slug } } } }
			sort: { fields: price, order: DESC }
		) {
			edges {
				node {
					id
					duration
					price
					slug
					title
					popular
					new
					featuredImage {
						localFile {
							publicURL
							childImageSharp {
								gatsbyImageData(
									layout: FULL_WIDTH
									placeholder: BLURRED
									aspectRatio: 1.3
								)
							}
						}
					}
					slider {
						id
						url
						name
						alternativeText
						localFile {
							childImageSharp {
								gatsbyImageData(aspectRatio: 1.3)
							}
						}
					}
					quickfacts {
						lodging
						level
					}
					activity {
						name
					}
					regions {
						name
						id
					}
					destination {
						name
					}
				}
			}
		}

		strapiSpeciality(slug: { eq: $slug }, locale: { eq: "en" }) {
			name
			desc
			slug
			image {
				name
				alternativeText
				localFile {
					childImageSharp {
						gatsbyImageData(aspectRatio: 1.2)
					}
				}
			}
		}
	}
`;

export default SpecialityPage;
